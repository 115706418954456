import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

import api from "@/api";
import VConsole from 'vconsole';

// const vConsole = new VConsole();
// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        // Add your API key here
      key: 'AIzaSyB4ut8vHY8sya0RSZN3RcpxfUMVxI0kAww', // Add your here your google map api key
        libraries: 'places', // This is required if you use the Auto complete plug-in
    },
})
import VueFbChat from 'vue-facebook-chat'
Vue.use(VueFbChat)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

import VueMoment from 'vue-moment'
Vue.use(VueMoment)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
